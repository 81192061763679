/**
 * Created by jerry on 2020/04/20.
 * 通用次卡价格调整 api
 */
import * as API from '@/api/index'

export default {
    // 获取时效卡价格调整列表
    timeCardPriceAdjustmentList: params => {
        return API.POST('api/timeCardPriceAdjustment/list', params)
    },
    // 新增时效卡价格调整
    timeCardPriceAdjustmentCreate: params => {
        return API.POST('api/timeCardPriceAdjustment/create', params)
    },
    // 更新时效卡价格调整
    timeCardPriceAdjustmentUpdate: params => {
        return API.POST('api/timeCardPriceAdjustment/update', params)
    },
    //删除通用次卡价格调整
    timeCardPriceAdjustDelte: params => {
        return API.POST('api/timeCardPriceAdjustment/delete', params)
    },
    //获取全部时效卡
    timeCardAll: params => {
        return API.POST('api/timeCard/all', params)
    },
}
